import React from "react";

import { IoCheckmarkSharp } from "react-icons/io5";

// icons
import { Svgemojy, Svgemojy2, Svghappy } from "../../assets/Icons";

// css styles
import "./SubscribePackages.css";

// handle sorting plans based select value
export const PackagePlan = ({ item }) => {
	// Create a new sorted array without mutating the original array
	const sortedPlans = [...(item || [])]?.sort((a, b) => {
		if (a.selected === b.selected) return 0;
		return a.selected ? -1 : 1;
	});

	return (
		<div>
			{sortedPlans.map((plan, index) => (
				<h2
					style={{
						color: !plan?.selected ? "#ADB5B9" : "",
						fontSize: "18px",
						fontWeight: "400",
						display: "flex",
						justifyContent: "start",
						alignItems: "start",
						marginBottom: "0px",
					}}
					key={index}>
					<IoCheckmarkSharp
						style={{
							color: plan?.selected ? "#3AE374" : "#ADB5B9",
							display: "inline-block",
							marginLeft: "0.1em",
							width: "22px",
							height: "22px",
						}}
					/>
					<span
						style={{
							color: plan?.selected ? "#011723" : "#ADB5B9",
							whiteSpace: "normal",
							fontWeight: plan?.selected ? "500" : "400",
							display: "inline-block",
							lineHeight: "1.6",
						}}>
						{plan?.name}
					</span>
				</h2>
			))}
		</div>
	);
};
const PackagesInfo = ({ packages, highestPricedPackage }) => {
	// navigate to auth merchant by default
	const navigateToMerchantRegister = () => {
		window.location.href = "https://store.atlbha.com/auth/merchant";
	};

	return (
		<>
			<div className='content-package '>
				{packages?.map((pack) => (
					<>
						{pack?.status === "نشط" && (
							<div
								className={`py-4 box ${
									pack === highestPricedPackage ? "top-package" : ""
								}`}
								key={pack?.id}>
								<h3>{pack?.name}</h3>

								<h2 className='gap-1 d-flex justify-content-center align-items-baseline'>
									{pack?.discount > 0 ? (
										<>
											<span className='price '>
												{pack?.yearly_price - pack?.discount}
												<span className='currency pe-1'>ر.س</span>
											</span>
											<span className='discount-price '>
												{pack?.yearly_price}{" "}
												<span className='currency '>ر.س</span>
											</span>
										</>
									) : (
										<span className='price'>
											{pack?.yearly_price}{" "}
											<span className='currency '>ر.س</span>
										</span>
									)}
									<p className='package-type'>/سنوياََ</p>
								</h2>

								<ul>
									<PackagePlan item={pack?.plans} />
								</ul>

								<button
									onClick={() => {
										navigateToMerchantRegister();
									}}>
									ابدأ الآن
								</button>
							</div>
						)}
					</>
				))}
			</div>
		</>
	);
};

export default PackagesInfo;
