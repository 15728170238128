import React from "react";
import "./SearchSuggestionsResult.css";
import { Avatar, Skeleton } from "@mui/material";

const SearchSuggestionsResult = ({ suggestionsResult, setSearch, loading }) => {
	const SkeletonLoading = () => (
		<>
			{Array(6)
				.fill(null)
				.map((_, index) => (
					<div
						key={index}
						className='d-flex justify-content-start align-items-center gap-2 mb-2 pe-2'>
						<div className='img-box'>
							<Skeleton variant='circular'>
								<Avatar />
							</Skeleton>
						</div>
						<div className='section_name'>
							<Skeleton width={230} height={30} />
						</div>
					</div>
				))}
		</>
	);
	return (
		suggestionsResult?.length !== 0 && (
			<ul className='search_suggestions_result_wrapper'>
				{loading ? (
					<SkeletonLoading />
				) : (
					<>
						{suggestionsResult?.map((item) => (
							<li
								key={item?.id}
								onClick={() => {
									window.open(item?.url, "_blank");
									setSearch("");
								}}>
								<div className='d-flex justify-content-start align-items-center gap-2'>
									<div className='img-box'>
										<img
											className='img'
											src={item?.image}
											alt={item?.name || item?.store_name}
										/>
									</div>
									<div className='section_name'>
										{item?.name || item?.store_name}{" "}
									</div>
								</div>
							</li>
						))}
					</>
				)}
			</ul>
		)
	);
};

export default SearchSuggestionsResult;
