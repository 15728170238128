import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

// Components
import { LoadingPage } from "../components";
import PageTitle from "../components/PagesTitle/PageTitle";

// MUI
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// RTK Query
import { useGetCommonQuestionsQuery } from "../RTK/Api/commonQuestionApi";

function Faqs() {
	const [expanded, setExpanded] = useState(false);
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	// Common questions
	const { data: commonQuestion, isLoading } = useGetCommonQuestionsQuery();

	if (isLoading) {
		return <LoadingPage />;
	}
	return (
		<>
			<Helmet>
				<title>أطلبها | الأسئلة الشائعة</title>
				<meta name='الأسئلة الشائعة' content='الأسئلة الشائعة' />
			</Helmet>
			<div className='faqs-page'>
				<div className='container'>
					<PageTitle title={"الأسئلة الشائعة"} />
					<div className='faqs'>
						{commonQuestion?.map((item, index) => (
							<Accordion
								key={item?.id}
								expanded={expanded === index}
								onChange={handleChange(index)}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`${index}-content`}
									id={`${index}-header`}>
									<h6>{item?.question}</h6>
								</AccordionSummary>
								<AccordionDetails>
									<div
										dangerouslySetInnerHTML={{
											__html: item?.answer,
										}}
									/>
								</AccordionDetails>
							</Accordion>
						))}
					</div>
				</div>
			</div>
		</>
	);
}

export default Faqs;
