import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { SearchIcon } from "../../assets/Icons";
import SearchSuggestionsResult from "./SearchSuggestionsResult";

// RTK Query
import { useSearchInHomePageMutation } from "../../RTK/Api/searchInHomePageApi";

const SearchInput = () => {
	const [search, setSearch] = useState("");
	const [resetSearchResults, setSearchSuggestionsResult] = useState([]);

	// handle search in home page
	const [searchInHomePage, { isLoading, isFetching }] =
		useSearchInHomePageMutation();

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search !== "") {
				const fetchData = async () => {
					try {
						const response = await searchInHomePage({
							query: search,
						});

						setSearchSuggestionsResult(response?.data?.data?.results);
					} catch (error) {
						console.error("Error fetching searchInHomePage:", error);
					}
				};

				fetchData();
			} else {
				setSearchSuggestionsResult([]);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search, searchInHomePage]);

	return (
		<>
			<button>
				<SearchIcon />
			</button>

			<Form.Control
				type='search'
				placeholder=' البحث في المنتجات والمتاجر'
				className='me-2'
				aria-label='Search'
				value={search}
				onChange={(e) => {
					setSearch(e.target.value);
				}}
			/>

			<SearchSuggestionsResult
				suggestionsResult={resetSearchResults}
				setSearch={setSearch}
				loading={isLoading || isFetching}
				search={search}
			/>
		</>
	);
};

export default SearchInput;
